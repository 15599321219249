//Add strings
gdprCookieNoticeLocales.es = {
    description: 'Esta Web utiliza cookies para ofrecer y mejorar nuestros contenidos así como realizar labores analíticas. Puedes consultar nuestra política de cookies',
    readmore: 'aquí',
    settings: 'Configurar',
    accept: 'Aceptar',
    statement: 'Nuestro aviso de cookies',
    save: 'Guardar configuración',
    always_on: 'Siempre activas',
    cookie_essential_title: 'Cookies necesarias',
    cookie_essential_desc: 'Estas cookies son necesarias para que la página web funcione correctamente, incluida la que recordará lo que hayas marcado aquí y lo cumpla ;)',
    cookie_performance_title: 'Cookies de rendimiento',
    cookie_performance_desc: 'Estas cookies son usadas para mejorar el rendimiento de la página web pero no son esenciales. Por ejemplo el idioma predefinido del usuario',
    cookie_analytics_title: 'Cookies de analítica',
    cookie_analytics_desc: 'Estas cookies nos ayudan a medir cómo los usuarios interactuan con el contenido de la web, lo que nos ayuda a personalizar las páginas web y aplicaciones con el proposito de mejorar la experiencia de usuario',
    cookie_marketing_title: 'Cookies de marketing',
    cookie_marketing_desc: 'Estas cookies nos ayudan a ofrecerte una mejor experiencia de navegación, personalizar el contenido, los posibles anuncios que pueda tener el site, así como proporcionar funciones de redes sociales y analizar nuestro tráfico. Puedes conocer más sobre cuales usamos haciendo click en "Nuestro aviso de cookies"'
}
